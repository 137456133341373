.Header, .Membernavbar, .Navbar, .Footernavbar a, .Footercopy a, .Footer a {
  width: 100%;
  height: 50px;
  background-color: #660000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Header button {
  height: 24px;
  width: 100px;
  margin: 12px 12px;
  margin-top: 11px;
  background-color: #ff9900ea;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: none;
  border-radius: 4px;
  color: #660000;
  font-size: 11px;
  font-weight: 700;
}

.Header button:hover {
  color: #660000c4;
  background-color: #ff9900;
}

.Membernavbar {
  width: 100%;
  height: 22px;
  background-color: #66A7E2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Membernavbar a {
  color: #ffffffad;
  width: auto;
  font-size: 12px;
  padding: 0px 10px;
  text-decoration: none;
  font-weight: 300;
}

.Membernavbar a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}

@media (max-width: 550px) {
  .Membernavbar {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 30px;
  }
  .Membernavbar a {
    font-size: 11px;
  }
}

.Navbar {
  max-width: 800px;
  height: auto;
  margin: auto;
  background-color: #FFFFFF;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  position: relative;
  list-style-type: none;
  grid-gap: 15px;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  justify-items: center;
}

.Navbar img {
  padding: 3px 0px;
  width: auto;
  margin: 0 40px;
  height: 44px;
  display: inline-block;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.Navbar li {
  width: 100%;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.Navbar a {
  width: auto;
  margin-top: 17px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ff9900ea;
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
}

.Navbar a:hover {
  color: #ffc400ea;
  -webkit-text-decoration: dotted;
          text-decoration: dotted;
}

.Navbar .Searchicon {
  width: auto;
  height: auto;
  margin: 17px 40px;
  border: none;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media (min-width: 55em) {
  .Navbar {
    grid-auto-flow: column;
  }
}

.Slides {
  width: 82%;
  max-width: 1110px;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto auto;
  margin-top: -24px;
}

.Slides img {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: auto;
  height: auto;
  padding: 0 5px;
  margin: auto;
}

.Feature {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Feature img {
  width: 45px;
  height: auto;
  margin: -2px 0 0 -10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Feature .soundbutton {
  background: none;
  width: auto;
  height: auto;
  border: none;
  color: #660000;
  font-size: 12.8px;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Feature .soundbutton:hover {
  color: #660000c4;
}

.Aboutsectionheader {
  width: 100%;
  height: 50px;
  margin: auto auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Aboutsectionheader h1 {
  color: #660000;
  width: auto;
  font-size: 25px;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: 400;
}

.Vision {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Vision img {
  width: 200px;
  height: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #660000;
}

.Vision p {
  background-color: #FFFFFF;
  color: #646060;
  width: 500px;
  height: 200px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
  padding: 2.7% 2.0%;
  padding-top: 5.3%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: auto;
      -ms-flex-align: auto;
          align-items: auto;
}

.Mission {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Mission img {
  width: 200px;
  height: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #660000;
}

.Mission p {
  background-color: #FFFFFF;
  color: #646060;
  width: 500px;
  height: 200px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
  padding: 2.7% 2.0%;
  padding-top: 3.2%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: auto;
      -ms-flex-align: auto;
          align-items: auto;
}

.Statement {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Statement img {
  width: 200px;
  height: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #660000;
}

.Statement p {
  background-color: #FFFFFF;
  color: #646060;
  width: 500px;
  height: 200px;
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
  padding: 2.7% 2.0%;
  padding-top: 3.3%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: auto;
      -ms-flex-align: auto;
          align-items: auto;
}

.Leadership {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 30px;
}

.Leadership img {
  width: 200px;
  height: auto;
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #660000;
}

.Leadership p {
  background-color: #FFFFFF;
  color: #646060;
  width: 500px;
  height: 200px;
  font-size: 14px;
  font-stretch: condensed;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
  padding: 2.2% 1.5%;
  padding-top: 2.35%;
  line-height: 18px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: auto;
      -ms-flex-align: auto;
          align-items: auto;
}

@media (max-width: 550px) {
  .Leadership p {
    line-height: 14.9px;
    padding-top: 1.0%;
  }
}

hr {
  width: 40%;
  color: #646060;
  margin-top: 40px;
}

.Trainingsectionheader {
  width: 100%;
  height: 50px;
  margin-top: 17px;
  padding-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Trainingsectionheader h1 {
  color: #660000;
  width: auto;
  font-size: 25px;
  padding: 0px 10px;
  text-decoration: none;
  font-weight: 400;
}

.Training {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Training p {
  background-color: #FFFFFF;
  color: #646060;
  width: auto;
  height: auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 25px 25%;
  font-size: 14.2px;
  line-height: 20px;
  font-stretch: expanded;
  padding: 50px 40px;
  padding-right: 40px;
  padding-top: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .Training p {
    margin: 25px 18%;
  }
}

@media (max-width: 600px) {
  .Training p {
    margin: 25px 12%;
  }
}

.Donate {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 18px;
  background-color: #66A7E2;
  color: #660000;
  width: 57%;
  margin: auto auto;
  height: auto;
  padding: 0 30px;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #660000;
}

.Donate button {
  height: 24px;
  width: 100px;
  padding: auto auto;
  margin: 5px auto 16px auto;
  background-color: #ff9900ea;
  border: none;
  border-radius: 4px;
  color: #660000;
  font-size: 11px;
  font-weight: 700;
}

.Donate button:hover {
  color: #660000c4;
  background-color: #ff9900;
}

.Donate a {
  height: auto;
  width: 100px;
  border: none;
  border-radius: 4px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #660000;
  font-size: 11px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  color: #660000;
}

@media (max-width: 600px) {
  .Donate {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: row;
  }
}

.Resourcessectionheader {
  width: 100%;
  height: 50px;
  margin-top: 17px;
  margin-bottom: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Resourcessectionheader h1 {
  color: #660000;
  width: auto;
  font-size: 25px;
  padding: 0px 10px;
  text-decoration: none;
  font-weight: 400;
}

.Resources {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin: 0 20%;
}

@media (max-width: 900px) {
  .Resources {
    margin: 0 7%;
  }
}

.Resource1 {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  width: auto;
  height: auto;
  padding-bottom: 20px;
  background-color: #FFFFFF;
  margin: auto auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Resource1 h1 {
  color: #660000;
  width: auto;
  font-size: 12px;
  padding: 4px 0px;
  margin: auto 10%;
  text-decoration: none;
  font-weight: 400;
}

.Resource1 img {
  width: auto;
  height: 150px;
  margin: 0 10%;
  margin-top: 20px;
  padding: auto 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Resource1 p {
  color: #660000;
  width: auto;
  height: auto;
  margin: 0px 0px;
  padding: 0% 10%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #646060;
}

.Resource1 a {
  color: #660000;
  width: auto;
  margin: auto auto;
  height: auto;
  padding: 2.0px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #ff9900ea;
  font-weight: 400;
}

.Resource1 a:hover {
  color: #ffc400ea;
}

.Resource2 {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  width: auto;
  height: auto;
  padding-bottom: 20px;
  background-color: #FFFFFF;
  margin: auto auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Resource2 h1 {
  color: #660000;
  width: auto;
  font-size: 12px;
  padding: 4px 0px;
  margin: auto 10%;
  text-decoration: none;
  font-weight: 400;
}

.Resource2 img {
  width: auto;
  height: 150px;
  margin: 0 10%;
  margin-top: 20px;
  padding: auto 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Resource2 p {
  color: #660000;
  width: auto;
  height: auto;
  margin: 0px 0px;
  padding: 0% 10%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #646060;
}

.Resource2 a {
  color: #660000;
  width: auto;
  margin: auto auto;
  height: auto;
  padding: 2.0px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #ff9900ea;
  font-weight: 400;
}

.Resource2 a:hover {
  color: #ffc400ea;
}

.Resourcesnote {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Resourcesnote p {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #660000;
  width: 50%;
  max-width: 500px;
  margin: 20px auto -15px auto;
  height: auto;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #660000;
}

.Declarationsectionheader {
  width: 100%;
  height: 50px;
  margin-top: 17px;
  margin-bottom: 20px;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Declarationsectionheader h1 {
  color: #660000;
  width: auto;
  font-size: 25px;
  padding: auto auto;
  text-decoration: none;
  font-weight: 400;
}

.Declarationcards {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  margin: 0 32%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Declarationcards h2 {
  background-color: #FFFFFF;
  color: #660000;
  width: auto;
  margin: auto;
  width: auto;
  height: auto;
  font-size: 16px;
  padding: 0px 10px;
  text-decoration: none;
  font-weight: 400;
}

.Declarationcards p {
  background-color: #FFFFFF;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #646060;
  margin: auto;
  width: auto;
  height: auto;
  padding: 0 10px;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: none;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .Declarationcards {
    margin: 0 23%;
  }
}

.Testimonialsectionheader {
  width: 100%;
  height: 50px;
  margin-top: 17px;
  margin-bottom: 20px;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Testimonialsectionheader h1 {
  color: #660000;
  width: auto;
  font-size: 25px;
  padding: auto auto;
  text-decoration: none;
  font-weight: 400;
}

.Testimonialcards {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin: 0 400px;
  width: auto;
  background-color: #FFFFFF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
}

.Testimonialcards img {
  width: 80px;
  margin-top: 30px;
  height: auto;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

@media (max-width: 1200px) {
  .Testimonialcards {
    margin: 0 30%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (max-width: 800px) {
  .Testimonialcards {
    margin: 0 18%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.Testimonialtext {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  list-style-type: none;
}

.Testimonialtext p {
  color: #646060;
  width: auto;
  font-size: 15px;
  padding: auto auto;
  text-decoration: none;
  font-weight: 400;
  font-style: italic;
  margin: 3px 0;
}

.Testimonialtext h1 {
  color: #646060;
  width: auto;
  font-size: 15px;
  padding: auto auto;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  margin: 2px 0;
}

.Contactformsectionheader {
  width: 100%;
  height: 50px;
  margin-top: 17px;
  margin-bottom: 10px;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Contactformsectionheader h1 {
  color: #660000;
  width: auto;
  font-size: 25px;
  padding: auto auto;
  text-decoration: none;
  font-weight: 400;
}

.Contactform {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  padding: 0 30%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Contactform p, .Contactform h1, .Contactform h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #660000;
  margin: auto;
  width: auto;
  height: auto;
  padding: 0 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
  border: none;
  text-decoration: none;
}

.Contactform h1 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 500;
  padding: 4px;
  font-size: 20px;
}

.Contactform h2 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 400;
  padding: 4px;
  font-size: 18px;
}

.Contactform input {
  width: 220px;
  height: 20px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0px 0px 25px 0px #968080e3;
          box-shadow: inset 0px 0px 25px 0px #968080e3;
  border: none;
  outline: none;
}

.Contactform .Messagebox input {
  height: 40px;
}

.Contactform .Formsubheadings {
  color: #660000;
  margin: auto;
  line-height: 23px;
  width: auto;
  height: auto;
  padding: 0 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.Formfields {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px 0;
  padding-top: 17px;
}

.Formsubheadings {
  padding-top: 10px;
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px 0;
}

.Form {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  list-style-type: none;
  margin: 10px 30%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Formsubmitbutton {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 18px;
  color: #660000;
  width: 57%;
  margin: auto auto;
  height: auto;
  padding: 0 30px;
  margin-bottom: 100px;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-decoration: none;
  color: #660000;
}

.Formsubmitbutton button {
  height: 24px;
  width: 100px;
  padding: auto auto;
  margin: 5px auto 16px auto;
  background-color: #ff9900ea;
  border: none;
  border-radius: 4px;
  color: #660000;
  font-size: 11px;
  font-weight: 700;
}

.Formsubmitbutton button:hover {
  color: #660000c4;
  background-color: #ff9900;
}

.Formsubmitbutton a {
  height: auto;
  width: 100px;
  border: none;
  border-radius: 4px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #660000;
  font-size: 11px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  color: #660000;
}

.Footernavbar, .Footercopy {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  height: auto;
  background-color: #000000;
  color: #FFFFFF;
  padding: 20px;
  padding-bottom: 85px;
  font-size: 12px;
}

.Footernavbar a, .Footercopy a {
  width: 100%;
  background-color: #000000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #FFFFFF;
  text-decoration: none;
  margin: -18px 0;
}

.Footernavbar .Copyright, .Footercopy .Copyright {
  font-size: 12px;
}

.Footer {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: auto;
  background-color: #000000;
  color: #FFFFFF;
  padding: 20px;
  padding-bottom: 85px;
  font-size: 12px;
}

.Footer a {
  width: 100%;
  background-color: #000000;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #FFFFFF;
  text-decoration: none;
}

.Footercopy {
  margin: -20px 0;
  padding: 0px 2px;
  list-style-type: none;
}

.Footercopy li {
  padding: 3px;
}

@media (max-width: 500px) {
  .Footer {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: row;
  }
}
