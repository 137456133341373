.Slides {
    text-align: center;
  }
   
  .Slides-logo {
    height: 40vmin;
  }
   
  .Slides-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
   
  .Slides-link {
    color: #09d3ac;
  }
   
  body {
    background-image:
      linear-gradient(45deg, #F6EAEA 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #F6EAEA 75%),
      linear-gradient(45deg, transparent 75%, #F6EAEA 75%),
      linear-gradient(45deg, #F6EAEA 25%, rgba(246, 234, 234, 0.65));    

    background-size: 15px 15px;       

    background-position:0 0, 0 0, -7.5px -7.5px, 7.5px 7.5px;
}
   
  .slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
  }
  
  
  .slides {
    position: relative;
  }
  
  .slides .slick-prev,
  .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index:1;
  }
  
  .slides .slick-prev {
    left: 5%;
  }
  
  .slides .slick-next {
    right: 5%;
  }